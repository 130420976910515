@import url('https://fonts.googleapis.com/css?family=Silkscreen&display=swap');
@import url('https://fonts.googleapis.com/css?family=Quantico&display=swap');

body {
  background-color: rgb(40, 37, 53);
  padding-top: 20px;
  overscroll-behavior: none;
}

img {
  max-width:95%;
  max-height:95%;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.App {
  text-align: center;
  margin: 0 auto;
}

.App-logo {
  height: 90vmin;
  pointer-events: none;
}

.App section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-color: rgb(40, 37, 53);
}



.App header {
  background-color: #181717;
  height: 55px;
  color: white;
  position: fixed;
  width: 100%;
  max-width: 2728px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  padding: 10px;
  box-sizing: border-box;
}

.App-link {
  color: #61dafb;
}

.App-body {
  padding-top: 40px;
}

.TwoBlockGrid {
  display: grid;
  grid-template-columns: 3fr 2fr;
  height: 100%;
}

.TV-block {
  background-color: rgb(40, 37, 53);
}

.Chat-block {
  background-color: rgb(40, 37, 53);
  padding: 5%;
}

.TV-Image {
  width: 90%;
  padding-top: 1%;
  object-position: top;
}


.SignInOutButton {
  background-color: #282c34; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
}

.comment-Button {
  color: white;
  width: 20%;
  background-color: #969697;
  border-color: #afadad;
  border-width: 9px;  
  border-style: inset; 
  font-size: large;
  font-weight: bold;
  font-family: 'Silkscreen';
  cursor: pointer;
}

main {
  background-color: rgb(255, 255, 255);
  overflow-y: scroll;
  max-height: 900px;
  border-color: #afadad;
  border-width: 15px;  
  border-style: inset; 
}

main::-webkit-scrollbar {
  display: none;
}



form {
  height: 7vh;
  background-color: rgb(24, 23, 23);
  width: 100%;
  display: flex;
  font-size: 2rem;
}



input {
  line-height: 1.5;
  width: 100%;
  font-size: 1.5rem;
  color: rgb(0, 0, 0);
  outline: none;
  border-color: #afadad;
  border-width: 9px;  
  border-style: inset; 
  font-weight: lighter;
  font-family: 'Quantico';
}

ul, li {
  text-align: left;
  list-style: none;
  background-color: red;
}

p {
  max-width: 500px;
  margin-bottom: 12px;
  line-height: 40px;
  padding: 10px 20px;
  position: relative;
  color: white;
  text-align: center;
}

.comment {
  display: flex;
  align-items: top;
  padding-top: 15px;
}


.comment {
  flex-direction: row;
}

.comment-Text {
  margin: 0;
  color: rgb(0, 76, 255);
  text-align: left; 
  align-self: baseline;
  font-size: 30px;
  font-weight: lighter;
  font-family: 'Quantico';
}



.user-Name {
  margin: 0;
  color: rgb(194, 9, 194);
  font-family: 'Quantico';
  text-align: left; 
  align-self: baseline;
  font-size: 30px;
  font-weight: bolder;
}
